@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
/* @tailwind components; */
/* Stop purging. */

/* Write you own custom component styles here */

/* Start purging... */
/* @tailwind utilities; */
/* Stop purging. */

/* Your own custom utilities */

:root {
  --white: #fff;
  --black: #1b1e29;
  --blue: #2874c6;
  --green: #03815B;
  --yellow: #ffc100;
  --offWhite: #f6f8fb;
  --lightGray: #6E6F8C;
  --lighterGray: #e4e6ec;
  --lightestGray: #edf1f7;
  --gray: #B5BBCB;
}

body {
  font-family: "GeneralSans", sans-serif;
  color: var(--black);
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100vh;
  background: var(--black);
}

p {
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

* {
  box-sizing: border-box;
}

main > * {
  position: relative;
  z-index: 300;
  transform: translateZ(1px);
  overflow: hidden;
}

main > *:not(:last-child) {
  min-height: calc(100vh - 356px);
}

main {
  min-height: 100vh;
}

input[type="email"],
textarea,
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Light */
@font-face {
  font-family: "GeneralSans";
  src: url("../fonts/GeneralSans-Light.woff2") format("woff2"),
    url("../fonts/GeneralSans-Light.woff") format("woff"),
    url("../fonts/GeneralSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

/* Regular */
@font-face {
  font-family: "GeneralSans";
  src: url("../fonts/GeneralSans-Regular.woff2") format("woff2"),
    url("../fonts/GeneralSans-Regular.woff") format("woff"),
    url("../fonts/GeneralSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

/* Medium */
@font-face {
  font-family: "GeneralSans";
  src: url("../fonts/GeneralSans-Medium.woff2") format("woff2"),
    url("../fonts/GeneralSans-Medium.woff") format("woff"),
    url("../fonts/GeneralSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "GeneralSans";
  src: url("../fonts/GeneralSans-Bold.woff2") format("woff2"),
    url("../fonts/GeneralSans-Bold.woff") format("woff"),
    url("../fonts/GeneralSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
